import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following tables list the fields of Outbound Campaigns Voice data source that you can use in rules, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`Campaign Info`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the channel associated with the contact. One of the following: `}<ul><li>{`Voice`}</li><li>{`Video`}</li><li>{`Chat`}</li><li>{`Email`}</li><li>{`SMS`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For `}<strong parentName="td">{`outbound campaigns`}</strong>{`, name of the outbound voice campaign where Engagement Cloud created the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound campaign context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the skill of the operator or IVR that handled the contact.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Contact Info`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsubscribe date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Custom Parameters`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_19`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_22`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_23`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_26`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_27`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_28`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_29`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_31`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_33`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_34`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_35`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_36`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_37`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_38`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_39`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_40`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_41`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_42`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_43`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_44`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_45`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_46`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_47`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_48`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_50`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_param_9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Send Info`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of credits attributed to the operator that handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of contact creation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last status update date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the operator that handled the interaction with the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tags associated to the operator that handle the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      